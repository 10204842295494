.active-row {
    background-color: 'unset';
}

.inactive-row {
    /* background-color: lightgray; */
    opacity: 0.3;
}

.added {
    background-color: #ffeaea;
}
.loader {
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
}

.center-image {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: spin 2s linear infinite;
  background-color: 'transparent';
  color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  position: absolute;
  width: 75px;
  height: 75px;
  border-top: 3px solid #7846ff;
  border-radius: 50%;
  animation: spin2 2s linear infinite;
  top: 44%;
  left: 47%;
  z-index: 10000;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
.popup {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1rem;
}

.header {
    font-weight: bold;
}

.popupText {
    
}